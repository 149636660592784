.adminAddQuizPage__container {
    display: flex;
    /* background: url('../../../../public/images/exam.jpg') center/cover ; */
    color: black;

}

.adminAddQuizPage__sidebar {
    position: sticky;
    top: 0;
    flex: 0.2; /* Adjust the width for smaller screens */
    height: 100vh;
}

.adminAddQuizPage__content {
    
    flex: 0.8; /* Adjust the width for smaller screens */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adminAddQuizPage__content--card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(30px);
    border-radius: 20px;
    padding: 20px;
    margin: 20px auto;
    width: 100%; /* Take full width */
    max-width: 600px;
    /* margin: auto; */
    box-shadow: 10px 10px 20px rgba(0,0,0,0.4);
  
   
}

.adminAddQuizPage__content--button {
    display: block;
    width: 100%; /* Adjust the width for smaller screens */
    margin: 20px auto;
    background-color: rgb(68, 177, 49) !important;
    color: white;
}

.adminAddQuizPage__content--button:hover {
    background-color: rgb(16, 97, 1);
}
