.adminUpdateCategoryPage__container {
    display: flex;
}

.adminUpdateCategoryPage__sidebar {
    position: sticky;
    top: 0;
    flex: 0.01;
}

.adminUpdateCategoryPage__content {
    margin: 5px;
    padding: 8px;
    flex: 0.99;
}

.adminUpdateCategoryPage__content--button{
    display: block;
    width: 20%;
    margin: 20px auto;
    background: rgb(68 177 49);
    color:white;
}