@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap");
@import url('https://fonts.googleapis.com/css?family=Bangers|Cinzel:400,700,900|Lato:100,300,400,700,900|Lobster|Lora:400,700|Mansalva|Muli:200,300,400,600,700,800,900|Open+Sans:300,400,600,700,800|Oswald:200,300,400,500,600,700|Roboto:100,300,400,500,700,900&display=swap');


.coming-container {
  color: #fff;
  font-family: "Roboto Condensed", sans-serif;
  background-image: url(https://images.unsplash.com/photo-1508389377389-b8221c0bcc9e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
  background-position: top top;
  background-size: cover;
  margin: 0;
}

.coming-container {
  /* position: fixed; */
  height: 850px;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 90px;
  text-align: center;
  background: rgb(49, 49, 177);
  background: linear-gradient(
    0deg,
    rgba(49, 49, 177, 1) 0%,
    rgba(49, 49, 177, 1) 50%,
    rgba(49, 49, 177, 0.6) 100%
  );
  padding-top: 200px;
}

/* .container {
  width: 100%;
  margin: 15% auto;
}  */
.col-4 {
  width: 25%;
  float: left;
}

 /* h1 {
  font-size: 38px;
  text-transform: uppercase;
  line-height: 1;
} */

/* @media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 58px;
  }
} */


#countdown {
    width: 250px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    display: inline-block;
    text-align: center;
    margin: auto;
  }
  #countdown .box {
    padding: 10px;
    border-right: solid 1px rgba(255, 255, 255, 0.2);
  }
  #countdown .col-4:last-child .box {
    border-right-color: transparent;
  }
  #countdown .box p {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }
  #countdown .box .text {
    font-size: 12px;
    font-family: sans-serif;
  }
  
  /* @media (min-width: 768px) {
    .container {
      width: 1100px;
    }
    h1 {
      font-size: 58px;
    }
    #countdown {
      width: 350px;
      padding: 20px;
    }
  } */
  

/* Banner */
.banner-container{
  /* background: linear-gradient(84.53deg,#cdedeb 0,#e6f6f2 100%); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}
.logo-4 h3 {
  color: #e74c3c;
  font-weight: 300;
  font-size: 50px;
  line-height: 0.65;
  font-family: 'Lobster', cursive;
}
/* .logo-4 p {
  font-size: 14px;
  margin-left: 50px;
  color: #545454;
  font-weight: 400;
  text-transform: capitalize;
  font-style: italic;
  font-family: 'Mansalva', cursive;
} */

.text{
  margin-top: 0.8rem;
}
.text-decoration{
  font-size: 2rem;
}
.main-text{
  font-weight: 600;
}
.banner-list{
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
}
.list-items{
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: left; */
}
.check-img{
  width: 15px;
  height: 15px;
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .logo-4 h3{
    font-size: 2rem;
  }
} 