/* .adminCategoriesPage__container {
    display: flex;
}

.adminCategoriesPage__sidebar {
    position: sticky;
    top: 0;
    height: 100vh;
}

.adminCategoriesPage__content {
    width: 70%;
    height: fit-content;
    margin: 5px;
    padding: 8px;
}

.adminCategoriesPage__content--categoriesList {
    margin-top: 10px;
    border-bottom-style: inset;
    border-right-style: inset;
    border: 1px solid grey;
    justify-content: center;
}

.adminCategoriesPage__content--button {
    display: block;
    width: 20%;
    margin: 20px auto;
    background-color: rgb(68 177 49);
    color: white;
} */
.adminCategoriesPage__container {
    /* display: flex; */
    /* background: url('../../../../public/images/gov.jpg') center/cover ; */
    /* background-color: #fff; */

}

/* .adminCategoriesPage__sidebar {
    position: sticky;
    top: 0;
    height: 100vh;
} */

.adminCategoriesPage__content {
    flex: 1;
    height: fit-content;
    margin: 2px;
    padding: 2px;
}

/* .adminCategoriesPage__content h2 {
    color: #333;
} */

/* .adminCategoriesPage__content--categoriesList {
    display: flex;
    flex-wrap: wrap;
} */

.adminCategoriesPage__content--categoryCard {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s;
    
}

/* .adminCategoriesPage__content--categoryCard {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.adminCategoriesPage__content--categoryCard .card-body {
    padding: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
    background: rgba(255, 255, 255, 0.459);
    backdrop-filter: blur(60px);
    
}

.adminCategoriesPage__content--categoryCard .fw-bold {
    font-size: 1.2rem;
    color: #333;
}

/* .adminCategoriesPage__content--categoryCard .text-muted {
    color: #666;
} */

/* .adminCategoriesPage__content--categoryCard .update-delete-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
} */

/* .adminCategoriesPage__content--categoryCard .update-delete-buttons div {
    padding: 6px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
} */

/* .adminCategoriesPage__content--button {
    display: block;
    width: 20%;
    margin: 20px auto;
    background-color: rgb(68, 177, 49);
    color: white;
}
.adminCategoriesPage__content--button:hover {
    display: block;
    width: 20%;
    margin: 20px auto;
    background-color: rgb(16, 97, 1);
    color: white;
} */


/* Add this to your styles or use Tailwind CSS classes */

.inline-block-container {
    display: inline-block;
  }
  
  .inline-block-item {
    display: inline-block;
    vertical-align: top; /* Adjust vertical alignment as needed */
    margin-right: 10px; /* Add margin between items as needed */
  }
  
  .action-button {
    margin: 25px 8px;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .action-button:hover {
    /* color: rgb(45, 155, 218); Change color on hover */
    font-size: 18px;
  }
  
  .action-button.delete:hover {
    /* color: darkred; Change color on hover for delete button */
    font-size: 18px;
  }
  