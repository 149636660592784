main{
    width: 100%;
    padding: 0px;
}
.sidebar{
    background: #dc3545;
    color: #fff;
    width: 100%;
    height: 100vh;
    transition: all 0.5s;
    position: sticky;
}
.top_section{
    display: flex;
    align-items:center;
    padding:20px 15px;
}
.logo{
    font-size: 30px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 0;
}
.sidemenulink{
    display: flex;
    color: #fff;
    padding: 10px 10px;
    gap: 10px;
    transition: all 0.5s;
    text-decoration: none;

}
.sidemenulink:hover{
    background: #fff;
    color: #000;
    transition: all 0.5s;
    font-size: 20px;
}
.active{
    background: lightskyblue;
    color: #000;
    font-size: 18px;
}
.icon, .link_text{
    font-size: 17px;
    margin: 5px 3px;
}