.adminProfilePage__container {
    display: flex;
    background: url('../../../public/images/adminScreen.jpg') center/cover ;

    /* padding-top: 50px; */
}

.adminProfilePage__sidebar {
    position: sticky;
    top: 0;
}

.adminProfilePage__content {
    text-align: center;
    width: 100%;
    height: fit-content;
    margin:  auto;
    justify-content: center;
    align-items: center;
    display: flex;


}

.adminProfilePage__content--profilePic {
    border: 2px solid rgb(68 177 49);
   
    margin: auto;
}

.adminProfilePage__content--table {
    border: 1px solid gray;
    width: 50%;
    margin: 20px auto;
}

.card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    width: 700px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: black;
    text-align: center;
  }

  p {
    margin: 10px 0;
  }
  .text{
    padding-top: 40px;
  }

  @media (max-width: 768px) {
    .card {
      width: 80%;
    }
  }