.adminAddCategoryPage__container {
    display: flex;
    /* background: linear-gradient(to bottom, #0f2027, #203a43, #2c5364); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /* background: url('../../../../public/images/cate.jpg') center/cover ; */

    
}

.adminAddCategoryPage__sidebar {
    position: sticky;
    top: 0;
    flex: 0.01;

}
.adminAddCategoryPage__content {
    /* max-width: 600px; */
    width: 600px;
    margin: 20px auto ;
    padding: 20px;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  
    background: rgba(255, 255, 255, 0.142);
    backdrop-filter: blur(60px);
 }
  
  .adminAddCategoryPage__content h2 {
    text-align: center;
    color: white;
  }
  
  .my-2 {
    margin-bottom: 15px;
    color: white;
    /* width: 400px; */
  }
  
  .adminAddCategoryPage__content--button {
    display: block;
    width: 100%;
    background-color: rgb(68, 177, 49);
    color: white;
  }
  
  .adminAddCategoryPage__content--button:hover {
    background-color: rgb(16, 97, 1);
  }
  
 
  