.adminQuizzesPage__container {
    /* display: flex; */
}

/* .adminQuizzesPage__sidebar {
    position: sticky;
    top: 0;
    height: 100vh;
} */

/* .adminQuizzesPage__content {
    width: 50%;
    height: fit-content;
    padding: 50px;
    margin: auto;
    gap: 10px;
} */

/* .glass {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(70px);
    border-radius: 20px;
    margin: 5px;
    box-shadow: 5px 5px 100px rgb(84, 80, 80);
} */

.glass:hover {
    background-color: rgba(0, 0, 0, 0.163);
}

.adminQuizzesPage__content--quizzesList {
    width: 100% !important;
    margin-top: 5px;
    justify-content: center;
}
.adminQuizzesPage__content{
    flex: 1;
    height: fit-content;
    margin: 2px;
    padding: 2px;
}

.adminQuizzesPage__content--ButtonsList {
    display: flex;
    flex-wrap: wrap;
    margin: 5px;
}

/* .adminQuizzesPage__content--button {
    display: block;
    margin: 20px auto;
    background-color: rgb(68 177 49) !important;
    color: white !important;
} */
/* .adminQuizzesPage__content--button:hover {
  
    background-color: rgb(15, 99, 0);
    color: white;
} */
@media screen and (max-width: 768px) {
    .adminQuizzesPage__content {
        width: 80%; /* Adjust as needed for smaller screens */
        padding: 20px; /* Adjust padding for smaller screens */
    }

    .adminQuizzesPage__content--button {
        width: 50%; /* Adjust button width for smaller screens */
        /* margin: 0 40px; */
        
    }
    adminQuizzesPage__content--ButtonsList {
        width: 50%; /* Adjust button width for smaller screens */
       
        
    }
}
